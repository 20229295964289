import React from 'react';
import {graphql, Link} from 'gatsby';

import './styles.scss';

export interface PolicyTileProps {
  id: string,
  title: string,
  body: {
    body: string
  },
  policyUrl: string,
};

const PolicyTile:React.FC<PolicyTileProps> = ({title, body: {body}, policyUrl}:PolicyTileProps)  => {
  return (
    <a target="_blank" href={policyUrl} className="policy-tile-wrapper">
      <h3 className="policy-tile-title">{title}</h3>
      <div className="policy-tile-body">{body}</div>
      <div>
        <p className="policy-tile-link">Visit website</p>
      </div>

    </a>
  );
}

export default PolicyTile;

export const fragment = graphql`
  fragment PolicyTileFrag on ContentfulPolicyTile {
    id
    title
    body {
      body
    }
    policyUrl
  }
`;

import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';

export interface PageMenuProps {
  pageMenuItems: {
    title: string,
    slug: string,
    index: number
  }[]
};

const PageMenu:React.FC<PageMenuProps> = ({pageMenuItems}:PageMenuProps) => {
  return (<nav className="page-menu-wrapper">
    <h2 className="page-menu-title">In this section:</h2>
    <ul className="page-menu-list">
      {pageMenuItems.map((menuItem, index) => {
        return(<li key={`page-menu-${index}`}>
          <a href={`#${menuItem.slug}`}>{menuItem.title}</a>
        </li>);
      })}
    </ul>
  </nav>);
};

export default PageMenu;
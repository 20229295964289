import React from 'react';
import {graphql, Link} from 'gatsby';
import classNames from 'classnames';

import './styles.scss';

export interface CommunityOrganisationProps {
  key: string,
  colour: string,
  id: string,
  title: string,
  organisationUrl: string,
};

const CommunityOrganisation:React.FC<CommunityOrganisationProps> = ({title, colour, organisationUrl}:CommunityOrganisationProps)  => {
  const communityClass = classNames('community-organisation-wrapper', colour);
  return (
    <a target="_blank" href={organisationUrl} className={communityClass}>
      <h3 className="community-organisation-title">{title}</h3>
    </a>
  );
}

export default CommunityOrganisation;

export const fragment = graphql`
  fragment CommunityOrganisationFrag on ContentfulCommunityOrganisation {
    id
    title
    organisationUrl
  }
`;

import React from 'react';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import './styles.scss';

export interface StandardBannerProps {
  id:string,
  bannerImage: {
    gatsbyImageData
  },
  pageIntroduction,
  title: string,
  slug: string,
};

const StandardBanner:React.FC<StandardBannerProps> = ({id, bannerImage, pageIntroduction, title, slug}:StandardBannerProps) => {
  const image = getImage(bannerImage.gatsbyImageData);
  return (<>
    <div className="standard-banner-wrapper" >
      <GatsbyImage className="standard-banner-image" image={image} alt="" />
      <div className="standard-banner-inner">
        <ul className="standard-banner-breadcrumb">
          <li><a href="/">Home</a></li>
          <li><a href={slug}>{title}</a></li>
        </ul>
        <div>
          <h1>
            {title}
          </h1>
        </div>
      </div>
    </div>
    <div className="standard-banner-page-description">
      {pageIntroduction && renderRichText(pageIntroduction)}
    </div>
  </>);
};

export default StandardBanner;

export const fragment = graphql`
  fragment StandardBannerFrag on ContentfulBannerStandard {
    id
    bannerImage {
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
    }
    pageIntroduction {
      raw
    }
  }
`;

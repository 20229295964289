import React from 'react';
import {graphql} from 'gatsby';
import {renderRichText, RenderRichTextData, ContentfulRichTextGatsbyReference} from "gatsby-source-contentful/rich-text"

import './styles.scss';

export interface InfographicProps {
  id:string,
  infographicImage: {
    file: {
      url: string
    }
  },
  infographicText
};

const Infographic:React.FC<InfographicProps> = ({id, infographicImage: {file: {url}}, infographicText: bodyText}) => {
  return (<div className="infographic-wrapper">
    <div className="infographic-inner">
        <div className="infographic-left">
          <img alt="" src={url} />
        </div>
        <div className="infographic-right">
          {renderRichText(bodyText)}
        </div>
      </div>
  </div>);
}

export default Infographic;

export const fragment = graphql`
  fragment InfographicFrag on ContentfulSectionInfographic {
    id
    infographicImage {
      file {
        url
      }
    }
    infographicText {
      raw
    }
  }
`;

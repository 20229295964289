import React from 'react';
import {graphql} from 'gatsby';
import CommunityOrganisation, {CommunityOrganisationProps} from '../../Elements/CommunityOrganisation';

import './styles.scss';

export interface CommunityOrganisationsProps {
  key: string,
  id: string,
  organisations: CommunityOrganisationProps[]
};

const CommunityOrganisations:React.FC<CommunityOrganisationsProps> = ({organisations}:CommunityOrganisationsProps) => {
  const colours = [
    'darkgreen',
    'orange',
    'grey',
    'midgreen',
    'lightgreen',
  ];
  let previousColour = -1;

  return (<div className="community-organisations-wrapper">
    {organisations.map((organisation, index) => {
      let colourIndex = Math.floor(Math.random() * 5);
      if(colourIndex === previousColour) {
        colourIndex = colourIndex + 1;
      }
      const colour = colours[colourIndex % 5];
      previousColour = colourIndex;
      return (<CommunityOrganisation colour={colour} key={`organisation-${index}-${organisation.id}`} {...organisation} />);
    })}
  </div>);
};

export default CommunityOrganisations;

export const fragment = graphql`
  fragment CommunityOrganisationsFrag on ContentfulSectionCommunityOrganisations {
    id
    organisations {
      ...CommunityOrganisationFrag
    }
  }
`;

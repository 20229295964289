import React from 'react';
import {graphql} from 'gatsby';
import {renderRichText, RenderRichTextData, ContentfulRichTextGatsbyReference} from "gatsby-source-contentful/rich-text"

import './styles.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface TeamMemberProps {
  key: string,
  id: string,
  __typename: string,
  name: string,
  institution: string,
  emailAddress: string,
  headshot: {
    gatsbyImageData
  },
  body,
  linkUrl
};

const TeamMember:React.FC<TeamMemberProps> = ({id, headshot,name, emailAddress, institution}) => {
  const image = getImage(headshot);
  return (<div className="team-member-wrapper">
    <GatsbyImage alt="" image={image} />
    <div className="team-member-inner">
      <h3>{name}</h3>
      <div className="team-member-institution">{institution}</div>
      <div className="team-member-email">{emailAddress}</div>
    </div>
  </div>);
}

export default TeamMember;

export const fragment = graphql`
  fragment TeamMemberFrag on ContentfulTeamMember {
    id
    __typename
    name
    emailAddress
    institution
    headshot {
      gatsbyImageData(placeholder: BLURRED, width: 500)
    }
  }
`;

import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';


export interface SeeMoreProps {
  key: string,
  id: string,
  seeMoreUrl: string
};

const SeeMore:React.FC<SeeMoreProps> = ({seeMoreUrl}:SeeMoreProps) => {
  return (<div className="see-more-wrapper">
    <a href={seeMoreUrl}>See more here</a>
  </div>);
};

export default SeeMore;

export const fragment = graphql`
  fragment SeeMoreFrag on ContentfulSectionSeeMore {
    id
    seeMoreUrl
  }
`;

import React, {useCallback, useState} from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';

import Navbar from '../components/Elements/NavBar';
import Footer from '../components/Elements/Footer';
import PageMenu from '../components/Elements/PageMenu';

import TallBanner, {TallBannerProps} from '../components/Headers/TallBanner';
import StandardBanner, {StandardBannerProps} from '../components/Headers/StandardBanner';

import LinkTiles, {LinkTilesProps} from '../components/Sections/LinkTiles';
import Statistics, {StatisticsProps} from '../components/Sections/Statistics';
import Text, {TextProps} from '../components/Sections/Text';
import Title, {TitleProps} from '../components/Sections/Title';
import Infographic, {InfographicProps} from '../components/Sections/Infographic';
import StoryTiles, {StoryTilesProps} from '../components/Sections/StoryTiles';
import YellowHighlight, {YellowHighlightProps} from '../components/Sections/YellowHighlight';
import Testimonial, {TestimonialProps} from '../components/Sections/Testimonial';
import Video, {VideoProps} from '../components/Sections/Video';
import HighlightedFileDownload, {HighlightedFileDownloadProps} from '../components/Sections/HighlightedFileDownload';
import PartnerList, {PartnerListProps} from '../components/Sections/PartnerList';
import Highlight, {HighlightProps} from '../components/Sections/Highlight';
import SeeMore, {SeeMoreProps} from '../components/Sections/SeeMore';
import Gallery, {GalleryProps} from '../components/Sections/Gallery';
import ResourceTiles, {ResourceTilesProps} from '../components/Sections/ResourceTiles';
import TwoColumns, {TwoColumnsProps} from '../components/Sections/TwoColumns';
import NumberedBlock, {NumberedBlockProps} from '../components/Sections/NumberedBlock';
import ResearchTiles, {ResearchTilesProps} from '../components/Sections/ResearchTiles';
import ResearchLink, {ResearchLinkProps} from '../components/Sections/ResearchLink';
import CommunityOrganisations, {CommunityOrganisationsProps} from '../components/Sections/CommunityOrganisations';
import SpecialInterestGroups, {SpecialInterstGroupsProps} from '../components/Sections/SpecialInterestGroups';
import TeamList, {TeamListProps} from '../components/Sections/TeamList';
import PolicyTiles, {PolicyTilesProps} from '../components/Sections/PolicyTiles';
import Image, {ImageProps} from '../components/Sections/Image';

import {slugify} from '../utils/utils';

import './landingPage.scss';

interface LandingPageProps {
  data: {
    contentfulLandingPage: {
      title: string,
      slug: string,
      pageHeader: {
        __typename: string,
        id: string,
      },
      pageSection: {
        __typename: string,
        id: string,
      }[],
    }
  }
};

const headerTypeMap: {[headerName:string]: React.FC<TallBannerProps & StandardBannerProps>} = {
  ContentfulBannerTall: TallBanner,
  ContentfulBannerStandard: StandardBanner,
};

const sectionTypeMap: {[sectionName:string]: React.FC<
  StatisticsProps & 
  TextProps & 
  LinkTilesProps & 
  TitleProps & 
  InfographicProps & 
  YellowHighlightProps & 
  StoryTilesProps & 
  VideoProps & 
  TestimonialProps & 
  HighlightedFileDownloadProps & 
  PartnerListProps & 
  HighlightProps & 
  SeeMoreProps &
  GalleryProps &
  ResourceTilesProps &
  TwoColumnsProps &
  NumberedBlockProps &
  ResearchTilesProps &
  ResearchLinkProps &
  CommunityOrganisationsProps &
  SpecialInterstGroupsProps &
  TeamListProps &
  PolicyTilesProps &
  ImageProps>} = {
  ContentfulSectionStatistics: Statistics,
  ContentfulSectionText: Text,
  ContentfulSectionLinkTiles: LinkTiles,
  ContentfulSectionTitle: Title,
  ContentfulSectionInfographic: Infographic,
  ContentfulSectionStoryTiles: StoryTiles,
  ContentfulSectionYellowHighlight: YellowHighlight,
  ContentfulSectionTestimonial: Testimonial,
  ContentfulSectionVideo: Video,
  ContentfulSectionHighlightedFileDownload: HighlightedFileDownload,
  ContentfulSectionPartnerList: PartnerList,
  ContentfulSectionHighlight: Highlight,
  ContentfulSectionSeeMore: SeeMore,
  ContentfulSectionGallery: Gallery,
  ContentfulSectionResourceTiles: ResourceTiles,
  ContentfulSectionTwoColumns: TwoColumns,
  ContentfulSectionNumberedBlock: NumberedBlock,
  ContentfulSectionResearchTiles: ResearchTiles,
  ContentfulSectionResearchLink: ResearchLink,
  ContentfulSectionCommunityOrganisations: CommunityOrganisations,
  ContentfulSectionSpecialInterestGroups: SpecialInterestGroups,
  ContentfulSectionTeamList: TeamList,
  ContentfulSectionPolicyList: PolicyTiles,
  ContentfulSectionImage: Image,
};

const LandingPage: React.FC<LandingPageProps> = ({
  data: {
    contentfulLandingPage: page
  }
}: LandingPageProps) => {
  const Header = page.pageHeader && page.pageHeader.__typename && page.pageHeader?.__typename ?  headerTypeMap[page.pageHeader.__typename] : null;
  // const {__typename, ...headerParams} =  page.pageHeader;
  const menuItems = [
    {
      label: 'Research',
      linkTo: '/research'
    },
    {
      label: 'Policy',
      linkTo: '/policy'
    },
    {
      label: 'Practice',
      linkTo: '/practice'
    },
    {
      label: 'Resources',
      linkTo: '/resources'
    },
    {
      label: 'Contact us',
      linkTo: '/contact-us'
    },
  ];
  
  const pageMenuItems = page.pageSection?.filter((pageMenuItem) => pageMenuItem.__typename === 'ContentfulSectionTitle' && pageMenuItem.showInSectionList).map((pageMenuItem, index) => ({title: pageMenuItem.title, slug: slugify(pageMenuItem.title), index}));

  return (
    <>
      <Helmet>
        <title>{ page.title } | March Legacy</title>
      </Helmet>
      <header>
        <Navbar slug={page.slug} menuItems={menuItems} />
        {Header && <Header title={page.title} slug={page.slug} {...page.pageHeader} />}
      </header>
      <main className={`page-${page.slug === '/' ? 'home' : page.slug.replace('/', '')}`}>
        {pageMenuItems && pageMenuItems.length > 0 && <PageMenu pageMenuItems={pageMenuItems} />}
        {page.pageSection?.map(
          ({__typename: sectionType, id, ...sectionParams}, sectionIndex) => {
            const Section = sectionTypeMap[sectionType];
            return Section ? (
              <Section
                {...sectionParams}
                key={`id-${sectionIndex}-${id}`}
              />
            ) : (
              <div key={`id-${sectionIndex}`}>{`Unknown section type: ${sectionType}`}</div>
            );
          }
        )}
      </main>
      <Footer />
    </>
  )
};

export default LandingPage;

export const landingPageQuery = graphql`
  query contentfulLandingPage($id: String) {
    contentfulLandingPage(id: {eq: $id}) {
      id
      slug
      title
      showSectionMenu
      pageHeader {
        __typename
        ...TallBannerFrag
        ...StandardBannerFrag
      }
      pageSection {
        __typename
        ...StatisticsFrag
        ...TextFrag
        ...LinkTilesFrag
        ...TitleFrag
        ...InfographicFrag
        ...StoryTilesFrag
        ...YellowHighlightFrag
        ...TestimonialFrag
        ...VideoFrag
        ...HighlightedFileDownloadFrag
        ...PartnerListFrag
        ...HighlightFrag
        ...SeeMoreFrag
        ...GalleryFrag
        ...ResourceTilesFrag
        ...TwoColumnsFrag
        ...NumberedBlockFrag
        ...ResearchTilesFrag
        ...ResearchLinkFrag
        ...CommunityOrganisationsFrag
        ...SpecialInterestGroupsFrag
        ...TeamListFrag
        ...PolicyTilesFrag
        ...ImageFrag
      }
    }
  }
`;

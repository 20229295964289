import React from 'react';
import {graphql} from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import './styles.scss';

export interface HighlightedResearchTileProps {
  id: string,
  __typename: string,
  title: string,
  researchThumbnail: ImageDataLike,
  body: {
    body: string
  },
  linkUrl: string,
};

const HighlightedResearchTile:React.FC<HighlightedResearchTileProps> = ({id, researchThumbnail, title, body, linkUrl}) => {
  const image = getImage(researchThumbnail);
  return (<div className="highlighted-research-tile">
    <a target="_blank" className="highlighted-research-tile-link" href={linkUrl}>
      <div className="highlighted-research-tile-image-wrapper">
        <GatsbyImage alt="" image={image} />
      </div>
      <div className="highlighted-research-tile-content-wrapper">
        <h3>{title.title}</h3>
        <div className="highlighted-research-tile-body">
            {body.body}
          </div>
      </div>
    </a>
  </div>)
};

export default HighlightedResearchTile;

export const fragment = graphql`
fragment HighlightedResearchTileFrag on ContentfulHighlightedResearchTile {
  id
  __typename
  title {
    title
  }
  body {
    body
  }
  researchThumbnail {
    gatsbyImageData(placeholder: BLURRED, width: 500)
  }
  linkUrl
}
`;
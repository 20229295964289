import React from 'react';
import {graphql} from 'gatsby';
import Partner, {PartnerProps} from '../../Elements/Partner';

import './styles.scss';

export interface PartnerListProps {
  id: string
  partners: PartnerProps[]
};

const PartnerList:React.FC<PartnerListProps> = ({id, partners}:PartnerListProps) => {
  return (<div className="partner-list-wrapper">
    {partners.map((partner, index) => {
      return (<Partner key={`partner-${index}-${partner.id}`} {...partner} />);
    })}
  </div>);
};

export default PartnerList;

export const fragment = graphql`
  fragment PartnerListFrag on ContentfulSectionPartnerList {
    id
    partners {
      ...PartnerFrag
    }
  }
`;

import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface HighlightedFileDownloadProps {
  body,
  thumbnail: {
    gatsbyImageData,
    title
  },
  document: {
    file: {
      url: string
    }
  }
};

const HighlightedFileDownload:React.FC<HighlightedFileDownloadProps> = ({body, thumbnail, document: {file: {url: fileUrl}}}) => {
  const image = getImage(thumbnail);
  return (<div className="highlighted-download-wrapper">
    <div className="highlighted-download-left">
      {renderRichText(body)}
      <div>
        <a target="_blank" className="highlighted-download-button" href={fileUrl}>Download PDF</a>
      </div>
    </div>
    <div className="highlighted-download-right">
      <GatsbyImage image={image} alt={thumbnail.title} />
    </div>
  </div>);
}

export default HighlightedFileDownload;

export const fragment = graphql`
  fragment HighlightedFileDownloadFrag on ContentfulSectionHighlightedFileDownload {
    body {
      raw
    }
    thumbnail {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
    document {
      file {
        url
      }
    }
  }
`;

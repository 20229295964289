import React from 'react';
import {graphql, Link} from 'gatsby';

import './styles.scss';

export interface ResearchTileProps {
  id: string,
  __typename: string,
  title: {
    title: string,
  }
  authors: {
    authors: string
  },
  publication: string,
  researchUrl: string,
};

const ResearchTile:React.FC<ResearchTileProps> = ({title: {title}, authors: {authors}, publication, researchUrl}:ResearchTileProps)  => {
  return (
    <a target="_blank" href={researchUrl} className="research-tile-wrapper">
      <div className="research-tile-icon">
        <img src="/assets/images/icon-research-document.png" alt="" />
      </div>
      <h3 className="research-tile-title">{title}</h3>
      <p className="research-tile-authors">{authors}</p>
      <p  className="research-tile-publication">{publication}</p>

    </a>
  );
}

export default ResearchTile;

export const fragment = graphql`
  fragment ResearchTileFrag on ContentfulResearchTile {
    id
    __typename
    title {
      title
    }
    authors {
      authors
    }
    publication
    researchUrl
  }
`;

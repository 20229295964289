import React from 'react';
import {graphql} from 'gatsby';
import {renderRichText, RenderRichTextData, ContentfulRichTextGatsbyReference} from "gatsby-source-contentful/rich-text"

import './styles.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface StoryTileProps {
  key: string,
  id: string,
  title: string,
  storyThumbnail: {
    gatsbyImageData
  },
  body,
  linkUrl
};

const StoryTile:React.FC<StoryTileProps> = ({id, storyThumbnail, title, body, linkUrl}) => {
  const image = getImage(storyThumbnail);
  return (<div className="story-tile-wrapper">
    <a target="_blank" className="story-tile-link" href={linkUrl}>
      <GatsbyImage alt="" image={image} />
      <div className="story-tile-inner">
        <h3>{title}</h3>
        <div className="story-tile-body">
          {renderRichText(body)}
        </div>
        <div>
          <button>Learn more</button>
        </div>
      </div>
    </a>
  </div>);
}

export default StoryTile;

export const fragment = graphql`
  fragment StoryTileFrag on ContentfulStoryTile {
    id
    title
    storyThumbnail {
      gatsbyImageData(placeholder: BLURRED, width: 500)
    }
    body {
      raw
    }
    linkUrl
  }
`;

import React from 'react';
import {graphql} from 'gatsby';

import Text, {TextProps} from '../Text';
import ResourceTiles, {ResourceTilesProps} from '../ResourceTiles';
import Image, {ImageProps} from '../Image';

import './styles.scss';

export interface TwoColumnsProps {
  key: string,
  id: string,
  leftColumn: {
    __typename: string,
    id: string,
  }[],
  rightColumn: {
    __typename: string,
    id: string,
  }[],
};

const sectionTypeMap: {[sectionName:string]: React.FC<
  ImageProps &
  ResourceTilesProps &
  TextProps>} = {
  ContentfulSectionImage: Image,
  ContentfulSectionText: Text,
  ContentfulSectionResourceTiles: ResourceTiles,
};

const TwoColumns:React.FC<TwoColumnsProps> = ({id, leftColumn, rightColumn}:TwoColumnsProps) => {
  return (<div className="two-column-wrapper">
    <div className="two-column-col two-column-left">
      {leftColumn.map((columnItem, index) => {
        const Section = sectionTypeMap[columnItem.__typename];
        return <Section key={`column-${index}-${columnItem.id}`} {...columnItem} />;
      })}
    </div>
    <div className="two-column-col two-column-right">
      {rightColumn.map((columnItem, index) => {
        const Section = sectionTypeMap[columnItem.__typename];
        return <Section key={`column-${index}-${columnItem.id}`} {...columnItem} />;
      })}
    </div>
  </div>);
};

export default TwoColumns;

export const fragment = graphql`
  fragment TwoColumnsFrag on ContentfulSectionTwoColumns {
    leftColumn {
      __typename
      ...TextFrag
      ...ResourceTilesFrag
    }
    rightColumn {
      __typename      
      ...ImageFrag
    }
  }
`;

import React from 'react';
import {graphql} from 'gatsby';

import Statistic, {StatisticProps} from '../../Elements/Statistic';

import './styles.scss'

export interface StatisticsProps {
  key: string,
  id: string
  statistics: StatisticProps[]
}

const Statistics: React.FC<StatisticsProps> = ({
  statistics
}: StatisticsProps) => {
  return (<div className="statistics-wrapper">
      {statistics.map((statisticProps, statisticIndex) => {
        return (
          <Statistic key={`statistic-${statisticIndex}`} {...statisticProps} />
        )
      })}
  </div>);
};

export default Statistics;

export const fragment = graphql`
  fragment StatisticsFrag on ContentfulSectionStatistics {
    id
    statistics {
      ...StatisticFrag
    }
  }
`;
import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface StatisticProps {
  id: string,
  label: string,
  number: string,
  icon: {
    gatsbyImageData
  }
};

export const Statistic:React.FC<StatisticProps> = ({
  id,
  label,
  number,
  icon
}: StatisticProps) => {
  const image = getImage(icon);
  return (
    <div className="statistic-wrapper">
      <div className="statistic-image-wrapper">
        <GatsbyImage alt="" className="statistic-image" image={image} />
      </div>
      <div className="statistic-number">{number}</div>
      <div className="statistic-label">{label}</div>
    </div>
  );
};

export default Statistic;

export const fragment = graphql`
  fragment StatisticFrag on ContentfulStatistic {
    id
    number
    label
    icon {
      gatsbyImageData(placeholder: BLURRED, width: 500)
    }
  }
`;

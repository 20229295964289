import React from 'react';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import './styles.scss';

export interface PartnerProps {
  id: string,
  partnerLogo: {
    gatsbyImageData
  }
  name: string
};

const Partner:React.FC<PartnerProps> = ({name, partnerLogo}:PartnerProps) => {
  const image = getImage(partnerLogo.gatsbyImageData);
  return (<div className="partner-wrapper">
    <GatsbyImage alt={`${name} logo`} image={image} />
  </div>);
};

export default Partner;

export const fragment = graphql`
  fragment PartnerFrag on ContentfulPartner {
    id
    name
    partnerLogo {
      gatsbyImageData(placeholder: BLURRED, width: 500)
    }
  }
`;

import React from 'react';
import {graphql, Link} from 'gatsby';

import './styles.scss';
import {GatsbyImage, getImage } from 'gatsby-plugin-image';


export interface ResourceTileProps {
  id: string,
  __typename: string,
  title: string,
  thumbnail: {
    gatsbyImageData
  },
  url: string
};

const ResourceTile:React.FC<ResourceTileProps> = ({title, thumbnail, url}:ResourceTileProps)  => {
  const image = getImage(thumbnail);
  return (
    <a target="_blank" href={url} className="resource-tile-wrapper">
      {image && <GatsbyImage objectFit="contain" className="resource-tile-thumbnail" alt="" image={image} />}
      <h3>{title}</h3>
      {url && <img className="resource-tile-button" src="/assets/images/icon-arrow.png" alt="" />}
    </a>
  );
}

export default ResourceTile;

export const fragment = graphql`
  fragment ResourceTileFrag on ContentfulResourceTile {
    id
    __typename
    title
    thumbnail {
      gatsbyImageData(placeholder: BLURRED, width: 500)
    }
    url
  }
`;

import React from 'react';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import './styles.scss';

export interface TallBannerProps {
  title: string,
  bannerImage: {
    gatsbyImageData
  },
  bannerText: {
    bannerText: string
  }
};

const TallBanner:React.FC<TallBannerProps> = ({bannerImage, bannerText: {bannerText}}:TallBannerProps)  => {
  const image = getImage(bannerImage.gatsbyImageData);
  return (
    <div className="tall-banner-wrapper">
      <GatsbyImage className="tall-banner-image" image={image} alt="" />
      <h1>{bannerText}</h1>
    </div>
  );
};

export default TallBanner;

export const fragment = graphql`
  fragment TallBannerFrag on ContentfulBannerTall {
    id
    bannerImage {
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
    }
    bannerText {
      bannerText
    }
  }
`;

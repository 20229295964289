import React from 'react';
import {graphql} from 'gatsby';
import {renderRichText, RenderRichTextData, ContentfulRichTextGatsbyReference} from "gatsby-source-contentful/rich-text"

import SpecialInterestGroup, {SpecialInterestGroupProps} from '../../Elements/SpecialInterestGroup';
import './styles.scss';

export interface SpecialInterestGroupsProps {
  key: string,
  id: string,
  groups: SpecialInterestGroupProps[],
};

const SpecialInterestGroups:React.FC<SpecialInterestGroupsProps> = ({groups}:SpecialInterestGroupsProps) => {
  return (<div className="special-interest-groups-wrapper">
    {groups.map((group, index) => {
        return (<SpecialInterestGroup key={`group-tile-${index}-${group.id}`} {...group} />)
    })}
  </div>);
}

export default SpecialInterestGroups;

export const fragment = graphql`
  fragment SpecialInterestGroupsFrag on ContentfulSectionSpecialInterestGroups {
    id
    groups {
      ...SpecialInterestGroupFrag
    }
  }
`;

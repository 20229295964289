import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface YellowHighlightProps {
  key: string,
  id: string,
  title: string,
  body,
  image: {
    gatsbyImageData
  },
  linkUrl: string,
  linkText: string,

};

const YellowHighlight:React.FC<YellowHighlightProps> = ({id, title, body, linkUrl, linkText, image: imageData}) => {
  const image = getImage(imageData);
  return (<div className="yellow-highlight-wrapper">
    <div className="yellow-highlight-left">
      <GatsbyImage alt="" image={image} />
    </div>
    <div className="yellow-highlight-right">
      <div className="yellow-highlight-body">
        {renderRichText(body)}
      </div>
      {}
      {linkUrl && linkText && (<div className="yellow-highlight-link-wrapper">
        <a target="_blank" className="yellow-highlight-link" href={linkUrl}>{linkText}</a>
      </div>)}
    </div>
  </div>);
}

export default YellowHighlight;

export const fragment = graphql`
  fragment YellowHighlightFrag on ContentfulSectionYellowHighlight {
    id
    
    body {
      raw
    }
    image {
      gatsbyImageData(placeholder: BLURRED)
    }
    linkUrl
    linkText
  }
`;
import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';

export interface ResearchLinkProps {
  key: string,
  id: string,
  title: string,
  link: string,
};

const ResearchLink:React.FC<ResearchLinkProps> = ({title, link}:ResearchLinkProps) => {
  return (<a href={link} className="research-link-wrapper">
    <img src="/assets/images/icon-document.png" alt="" />
    <span>{title}</span>
  </a>);
};

export default ResearchLink;

export const fragment = graphql`
  fragment ResearchLinkFrag on ContentfulSectionResearchLink {
    id
    title
    link
  }
`;

import React from 'react';
import {graphql} from 'gatsby';
import ResourceTile, {ResourceTileProps} from '../../Elements/ResourceTile';

import './styles.scss';

export interface ResourceTilesProps {
  key: string,
  id: string,
  resourceTile: ResourceTileProps[]
};

const ResourceTiles:React.FC<ResourceTilesProps> = ({resourceTile}:ResourceTilesProps) => {
  return (<div className="resource-tiles-wrapper">
    {resourceTile.map((resource, index) => {
      return (<ResourceTile key={`resource-${index}-${resource.id}`} {...resource} />);
    })}
  </div>);
};

export default ResourceTiles;

export const fragment = graphql`
  fragment ResourceTilesFrag on ContentfulSectionResourceTiles {
    id
    resourceTile {
      ...ResourceTileFrag
    }
  }
`;

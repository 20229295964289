import React from 'react';
import {graphql} from 'gatsby';
import classNames from 'classnames';

import {slugify} from '../../../utils/utils';

import './styles.scss';

export interface TitleProps {
  id: string,
  title: string,
  showInSectionList: boolean
  backgroundColour: string[],
};

const Title:React.FC<TitleProps> = ({id, title, backgroundColour, showInSectionList}:TitleProps) => {
  const titleClasses = classNames(
    'section-title',
    `${backgroundColour[0].toLowerCase()}`
  );
  return (<div className="title-wrapper">
      {showInSectionList && <a name={slugify(title)}></a>}
      <h2 className={titleClasses}>
        {title}
      </h2>
    </div>);
};

export default Title;

export const fragment = graphql`
  fragment TitleFrag on ContentfulSectionTitle {
    id,
    title,
    showInSectionList
    backgroundColour
  }
`;
import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface HighlightProps {
  key: string,
  id:string,
  title: string,
  body: {
    raw:string
  },
  image: {
    gatsbyImageData
  },
  url: string
};

const Highlight:React.FC<HighlightProps> = ({id, title, body, image: imageData, url}:HighlightProps) => {
  const image = getImage(imageData);
  return (<div className="highlight-wrapper">
    <div className="highlight-left">
      <h3>{title}</h3>
      <div className="highlight-body">
        {body && renderRichText(body)}
      </div>
      <div className="highlight-button-wrapper">
        <a className="highlight-button" target="_blank" href={url}>Learn more</a>
      </div>
    </div>
    <div className="highlight-right">
      <GatsbyImage alt="" image={image} />
    </div>
  </div>);
};

export default Highlight;

export const fragment = graphql`
  fragment HighlightFrag on ContentfulSectionHighlight {
    id
    title
    body {
      raw
    }
    image {
      gatsbyImageData(placeholder: BLURRED)
    }
    url
  }
`;

import React from 'react';
import {graphql} from 'gatsby';
import PolicyTile, {PolicyTileProps} from '../../Elements/PolicyTile';

import './styles.scss';

export interface PolicyTilesProps {
  key: string,
  id: string,
  policies: PolicyTileProps[]
};

const PolicyTiles:React.FC<PolicyTilesProps> = ({policies}:PolicyTilesProps) => {
  return (<div className="policy-tiles-wrapper">
    {policies.map((policy, index) => {
      return (<PolicyTile key={`research-${index}-${policy.id}`} {...policy} />);
    })}
  </div>);
};

export default PolicyTiles;

export const fragment = graphql`
  fragment PolicyTilesFrag on ContentfulSectionPolicyList {
    id
    policies {
      ...PolicyTileFrag
    }
  }
`;

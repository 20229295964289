import React from 'react';
import {graphql} from 'gatsby';
import {renderRichText, RenderRichTextData, ContentfulRichTextGatsbyReference} from "gatsby-source-contentful/rich-text"
import classNames from 'classnames';
import {RichText} from '../../../utils/RichText';

import './styles.scss';

export interface TextProps {
  key: string,
  id: string,
  body,
  narrow: boolean,
};

const Text:React.FC<TextProps> = ({id, body, narrow}) => {
  const wrapperClasses = classNames(
    'text-wrapper',
    {
      narrow: narrow
    }
  );

  return (
    <div className={wrapperClasses}>
      {body &&  <RichText id={`body-${id}`} className="text-body" content={body} />}
    </div>
  );
};

export default Text;

export const fragment = graphql`
  fragment TextFrag on ContentfulSectionText {
    id
    body {
      raw
    }
    narrow
  }
`;

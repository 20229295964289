import React from 'react';
import {graphql} from 'gatsby';

import TeamMember, {TeamMemberProps} from '../../Elements/TeamMember';
import ResourceTile, {ResourceTileProps} from '../../Elements/ResourceTile';

import './styles.scss';

export interface TeamListProps {
  key: string,
  id: string,
  teamMember: (TeamMemberProps & ResourceTileProps)[],
};

const TeamList:React.FC<TeamListProps> = ({teamMember}:TeamListProps) => {
  return (<div className="team-list-wrapper">
    {teamMember.map((profile, index) => {
        switch(profile.__typename) {
          case 'ContentfulTeamMember':
            return (<TeamMember key={`team-member-${index}-${profile.id}`} {...profile} />);
          case 'ContentfulResourceTile':
            return (<ResourceTile key={`team-member-${index}-${profile.id}`} {...profile} />);
          default:
            return null;
        }
    })}
  </div>);
}

export default TeamList;

export const fragment = graphql`
  fragment TeamListFrag on ContentfulSectionTeamList {
    id
    teamMember {
      ...TeamMemberFrag
      ...ResourceTileFrag
    }
  }
`;

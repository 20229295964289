import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface GalleryProps {
  key: string,
  id: string,
  galleryImage: {
    gatsbyImageData
  }
};

const Gallery:React.FC<GalleryProps> = ({galleryImage}:GalleryProps) => {
  const image = getImage(galleryImage);
  return (<div className="gallery-wrapper">
    <GatsbyImage alt="" image={image} />
  </div>)
};

export default Gallery;

export const fragment = graphql`
  fragment GalleryFrag on ContentfulSectionGallery {
    galleryImage {
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
`;

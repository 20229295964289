import React from 'react';
import {INLINES} from '@contentful/rich-text-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, next) => {
      return <a href={node.data.uri} target={`${node.data.uri.startsWith('/') ? '_self' : '_blank'}`} >{node.content[0].value}</a>;
    }
  }
}

interface RichTextProps {
  content: {
    raw: string
  };
  className?: string;
  id?: string;
  style?: {
    [x: string]: string;
  };
  component?: string;
}


export const RichText:React.FC<RichTextProps> = ({
  content,
  className,
  id,
  style,
  component = 'div',
}) => 
  React.createElement(
    component, 
    {className, id, style},
    documentToReactComponents(JSON.parse(content?.raw), options)
  );

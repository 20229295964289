import React from 'react';
import {graphql} from 'gatsby';
import {renderRichText, RenderRichTextData, ContentfulRichTextGatsbyReference} from "gatsby-source-contentful/rich-text"

import StoryTile, {StoryTileProps} from '../../Elements/StoryTile';
import './styles.scss';

export interface StoryTilesProps {
  key: string,
  id: string,
  storyTile: StoryTileProps[],
};

const StoryTiles:React.FC<StoryTilesProps> = ({storyTile}:StoryTilesProps) => {
  return (<div className="story-tiles-wrapper">
    {storyTile.map((storyTile, index) => {
        return (<StoryTile key={`story-tile-${index}-${storyTile.id}`} {...storyTile} />)
    })}
  </div>);
}

export default StoryTiles;

export const fragment = graphql`
  fragment StoryTilesFrag on ContentfulSectionStoryTiles {
    id
    storyTile {
      ...StoryTileFrag
    }
  }
`;

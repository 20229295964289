import React from 'react';
import {graphql} from 'gatsby';
import ResearchTile, {ResearchTileProps} from '../../Elements/ResearchTile';
import HighlightedResearchTile, {HighlightedResearchTileProps} from '../../Elements/HighlightedResearchTile';

import './styles.scss';

export interface ResearchTilesProps {
  key: string,
  id: string,
  researchTiles: (ResearchTileProps & HighlightedResearchTileProps)[]
};

const ResearchTiles:React.FC<ResearchTilesProps> = ({researchTiles}:ResearchTilesProps) => {
  return (<div className="research-tiles-wrapper">
    {researchTiles.map((research, index) => {
      switch (research.__typename) {
        case 'ContentfulResearchTile':
          return (<ResearchTile key={`research-${index}-${research.id}`} {...research} />);
        case 'ContentfulHighlightedResearchTile':
          return (<HighlightedResearchTile key={`research-${index}-${research.id}`} {...research} />);
        default:
          return null;
      }
    })}
  </div>);
};

export default ResearchTiles;

export const fragment = graphql`
  fragment ResearchTilesFrag on ContentfulSectionResearchTiles {
    id
    researchTiles {
      ...ResearchTileFrag
      ...HighlightedResearchTileFrag
    }
  }
`;

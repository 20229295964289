import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';

export interface VideoProps {
  videoUrl: {
    videoUrl: string,
  },
};

const Video:React.FC<VideoProps> = ({videoUrl: {videoUrl}}:VideoProps) => {
  return (
    <div className="video-wrapper">
      <iframe src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );
};

export default Video;

export const fragment = graphql`
  fragment VideoFrag on ContentfulSectionVideo {
    id
    videoUrl {
      videoUrl
    }
  }  
`;

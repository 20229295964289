import React from 'react';
import {graphql, Link} from 'gatsby';
import LinkTile, {LinkTileProps} from '../../Elements/LinkTile';

import './styles.scss';

export interface LinkTilesProps {
  key: string,
  id: string,
  linkTile: LinkTileProps[],
};

const LinkTiles:React.FC<LinkTilesProps> = ({id, linkTile})  => {
  return (
    <div className="link-tiles-wrapper">
      {linkTile.map((tile, index) => {
        return (<LinkTile key={`link-${index}-${tile.id}`} {...tile} />);
      })}
    </div>
  );
}

export default LinkTiles;

export const fragment = graphql`
  fragment LinkTilesFrag on ContentfulSectionLinkTiles {
    __typename
    id
    linkTile {
      ...LinkTileFrag
    }
  }
`;

import React from 'react';
import {graphql, Link} from 'gatsby';
import {RenderRichTextData, ContentfulRichTextGatsbyReference, renderRichText} from 'gatsby-source-contentful/rich-text';

import './styles.scss';

export interface LinkTileProps {
  id: string,
  body: {
    raw: RenderRichTextData<ContentfulRichTextGatsbyReference>
  },
  linkTo: string
};

const LinkTile:React.FC<LinkTileProps> = ({body, linkTo}:LinkTileProps)  => {
  return (
    <div className="link-tile-wrapper">
      <Link to={linkTo}>
        {renderRichText(body)}
      </Link>
    </div>
  );
}

export default LinkTile;

export const fragment = graphql`
  fragment LinkTileFrag on ContentfulLinkTile {
    id
    body {
      raw
    },
    linkTo
  }
`;

import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';

export interface TestimonialProps {
  key: string,
  id: string,
  quote: {
    quote: string
  },
  attribution: string
};

const Testimonial:React.FC<TestimonialProps> = ({quote: {quote}, attribution}:TestimonialProps) => {
  return (<div className="testimonial-wrapper">
    <div className="testimonial-quote">{quote}</div>
    <div className="testimonial-attribution">{attribution}</div>
  </div>);
};

export default Testimonial;

export const fragment = graphql`
  fragment TestimonialFrag on ContentfulSectionTestimonial {
    id
    quote {
      quote
    }
    attribution
  }
`;

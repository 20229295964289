import React from 'react';
import {graphql} from 'gatsby';
import {renderRichText, RenderRichTextData, ContentfulRichTextGatsbyReference} from "gatsby-source-contentful/rich-text"

import './styles.scss';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

export interface SpecialInterestGroupProps {
  key: string,
  id: string,
  title: string,
  thumbnail: {
    gatsbyImageData
  },
  joinUrl: {
    joinUrl: string
  }
};

const SpecialInterestGroup:React.FC<SpecialInterestGroupProps> = ({id, thumbnail, title, joinUrl: {joinUrl}}) => {
  const image = getImage(thumbnail);
  return (<a target="_blank" className="special-interest-group-wrapper" href={joinUrl} >
    <GatsbyImage image={image} alt="" className="special-interest-group-image" />
    <div className="special-interest-group-inner">
      <h3>{title}</h3>
      <div>
        <button><img src="/assets/images/icon-arrow.png" />Join group</button>
      </div>
    </div>
  </a>);
}

export default SpecialInterestGroup;

export const fragment = graphql`
  fragment SpecialInterestGroupFrag on ContentfulSpecialInterestGroup {
    id
    title
    thumbnail {
      gatsbyImageData(placeholder: BLURRED, width: 500)
    }
    joinUrl {
      joinUrl
    }
  }
`;

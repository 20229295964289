import React from 'react';
import {graphql} from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import './styles.scss';

export interface NumberedBlockProps{
  key: string,
  id: string,
  number: number,
  body
};

const NumberedBlock:React.FC<NumberedBlockProps> = ({number, body}) => {
  return (<div className="numbered-block-wrapper">
    <div className="numbered-block-number"><div className="numbered-block-number-inner">{number}</div></div>
    <div className="numbered-block-body">{renderRichText(body)}</div>
  </div>);
};

export default NumberedBlock;

export const fragment = graphql`
  fragment NumberedBlockFrag on ContentfulSectionNumberedBlock {
    id
    number
    body {
      raw
    }
  }
`;

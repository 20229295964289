import React from 'react';
import {graphql} from 'gatsby';

import './styles.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface ImageProps {
  key: string,
  id: string,
  title:string,
  image: {
    gatsbyImageData
  }
};

const Image:React.FC<ImageProps> = ({title, image: imageData}:ImageProps) => {
  const image = getImage(imageData);
  return (<div className="image-wrapper">
    <GatsbyImage image={image} alt={title} />
  </div>)
};

export default Image;

export const fragment = graphql`
  fragment ImageFrag on ContentfulSectionImage {
    title
    image {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
`;
